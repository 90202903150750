import React, {createContext, useContext} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {db} from '../firebaseConfig';
import {GEMS_COLLECTION} from '../config/constant';

const gemsContext = createContext()

export const GemsProvider = ({children}) => {
    const [gems, loading, error] = useDocumentData(db.doc(`${GEMS_COLLECTION}/rgby`))
    if (loading)
        return null
    if (error) {
        console.error(error)
        return null
    }
    // console.log(gems)
    return (
        <gemsContext.Provider value={gems}>
            {children}
        </gemsContext.Provider>
    )
}

export const useGems = () => useContext(gemsContext)

import {setGlobal} from 'reactn';
import moment from 'moment';
import store from 'store';
import {USER_INFO_KEY} from './apis/constants';

setGlobal({
    calIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => 'Cal_' + i),
    numOfCals: 1,
    date: moment().format(),
    contents: [],
    schedules: [],
    dateList: [],
    showCart: false,
    subscriptions: [],
    metamor: [],
    tickets: 0,
    categories: [],
    user: store.get(USER_INFO_KEY) || {},
    myTickets: 0,
    anchorEl: null,
    loading: true,
    rankPrices: [],
    myPaid: [],
    history: [],
    hover: false,
    metamorHoverAnchor: null,
    hoverMetamor: {},
    hoverListRoom: false,
    totalOfTickets: 0,
    filterSchedules: [],
    tasks: {
        data: [],
        default: 0,
    },
    itemPopMiddle: {},
    colors: {primary: '#1790FF', secondary: '#690003'},
    openAddTask: null,
    showPay: false,
    packageCoins: null,
});

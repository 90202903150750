import React, {lazy, Suspense, useEffect} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import './App.css';
import {Route, Switch, withRouter} from 'react-router-dom';
import qs from 'query-string';
import {getUserData} from './components/actions/user';
import Cookies from 'js-cookie';
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN, IS_PRODUCTION} from './config/constant';
import {getSubscriptions} from './components/Cart/CartProductList';
import {OldBuyCourse} from './components/common/OldBuyCourse';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ggTokenChecked} from './common/ggToken';
import _ from 'lodash';
import {auth} from './firebaseConfig';
import {signInFirebase} from './common/user';
import ClfLinearProgress from './components/Custom/CLFLinearProgress';
import {getCoins} from './common/getCoins';
import {msgSuccess} from './utils/msg';
import store from 'store';
import {GemsProvider} from './context/GemsContext';
import {CoinsPackProvider} from './context/CoinsPackProvider';

const components = {
    MenuAppBar: lazy(() => import('./components/MenuAppBar/MenuAppBar')),
    HomePage: lazy(() => import('./components/HomePage/HomePage')),
    LiveStreaming: lazy(() => import('./components/LiveStreaming')),
    AndomEvent: lazy(() => import('./components/AndomEvent/index')),
    // HistoryComponent: lazy(() => import('./components/HistorySubs')),
    MetamorItems: lazy(() => import('./components/metamors/MetamorItems')),
    Cart: lazy(() => import('./components/Cart')),
    DialogBuyCoins: lazy(() => import('./components/BuyCoins/DialogBuyCoins')),
}

function App({history}) {
    const [user] = useGlobal('user');
    const [loading] = useGlobal('loading');
    const [ggCalInit] = useGlobal('ggCalInit');
    const [googleCalendarInit] = useGlobal('googleCalendarInit');
    const path = window.location.pathname;
    const query = qs.parse(window.location.search);
    useEffect(() => {
        if (ggCalInit) {
            ggTokenChecked();
        }
    }, [user, ggCalInit]);

    useEffect(() => {
        if (googleCalendarInit) {
            window.gapi.client.calendar.calendarList.list().then(({result}) => {
                const data = result.items;
                _.remove(data, g => {
                    return g.id.includes('#contacts@group')
                        || g.accessRole === 'freeBusyReader'
                        || g.id.includes('#holiday@group');
                });
                setGlobal({
                    googleCalendars: data,
                }, () => {

                });
            }).catch(console.log);
        }
    }, [googleCalendarInit]);

    useEffect(() => {
        const {
            token,
            refreshToken,
            showCart,
            donePaid,
        } = qs.parse(window.location.search);
        if (donePaid) {
            msgSuccess(' Thanks To Purchase ');
        }
        if (showCart) {
            setGlobal({showCart: true});
        }
        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (IS_PRODUCTION)
                opts.domain = '.geniam.com';
            store.set(ACCESS_TOKEN_KEY, token);
            Cookies.set(GENIAM_REFRESH_TOKEN, refreshToken, opts);
            store.set(GENIAM_REFRESH_TOKEN, refreshToken);

            delete query.token;
            delete query.refreshToken;
            if (_.isEmpty(query)) {
                // history.replace(`${path}`);
                window.history.replaceState(null, '', path)
            } else {
                history.push(`${path}?${qs.stringify(query)}`);
            }
        }
        getUserData().then(res => {
            OldBuyCourse();
            getSubscriptions();
            getCoins();
        }).catch(e => {
            console.log(e);
        });

        auth.onAuthStateChanged(user => {
            if (!user) {
                signInFirebase();
            }
        });

        // eslint-disable-next-line
    }, []);

    // useFetchData()

    if (loading)
        return <ClfLinearProgress/>;

    return (
        <Switch>
            <CoinsPackProvider>
                <Suspense fallback={<ClfLinearProgress/>}>
                    {
                        window.location.pathname !== "/andom/event/hichori" && <components.MenuAppBar/>
                    }

                    <div className={`App ${window.location.pathname !== "/andom/event/hichori" ? 'marTop' : ''}`}>
                        <Route exact path={'/'} component={components.HomePage}/>
                        {/*<Route exact path={'/history'} component={components.HistoryComponent}/>*/}
                        <Route exact path={'/mtview'} component={components.MetamorItems}/>
                        <GemsProvider>
                            <Route exact path={'/andom/event/:id/stream/:streamId'}
                                   component={components.LiveStreaming}/>
                            <Route exact path={'/andom/event/:id'} component={components.AndomEvent}/>
                        </GemsProvider>
                        <components.DialogBuyCoins/>
                        <components.Cart/>
                        <ToastContainer
                            hideProgressBar={true}
                            // closeOnClick={false}
                            autoClose={1500}
                        />
                    </div>
                </Suspense>
            </CoinsPackProvider>
        </Switch>
    );
}

// function NotFound() {
//     return (
//         <div>
//             Not Found
//         </div>
//     );
// }

export default withRouter(App);

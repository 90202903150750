import React, {createContext, useContext} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {db} from '../firebaseConfig';
import {COINS_PACK_COLL} from '../config/constant';

const coinsPackContext = createContext()

export const CoinsPackProvider = ({children}) => {
    const [coins, loading, error] = useDocumentData(db.doc(`${COINS_PACK_COLL}/cordemiaCoins`))
    // if (loading)
    //     return null
    // if (error) {
    //     console.error(error)
    //     return null
    // }
    // console.log({coins})
    return (
        <coinsPackContext.Provider value={coins}>
            {children}
        </coinsPackContext.Provider>
    )
}

export const useCoinsPack = () => useContext(coinsPackContext)

import axios from 'axios';
import {setGlobal} from 'reactn';

export const getCoins = async () => {
    try {
        const {data: {coins}} = await axios.get(
            `${process.env.REACT_APP_GENIAM_API}/coins`);

        setGlobal({
            myTickets: coins,
        });
    } catch (e) {
        console.log(e);
    }
};

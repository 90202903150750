export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
export const ACCESS_TOKEN_KEY = 'accessToken';

export const GENIAM_REFRESH_TOKEN = `ge_${process.env.REACT_APP_ENV}_refreshToken`;
export const USER_COLLECTION = IS_PRODUCTION ? 'users' : 'users-stg';
export const STREAM_COLLECTION = IS_PRODUCTION
    ? 'questumLive'
    : 'questumLive-stg';
export const CALENDAR_COLLECTION = IS_PRODUCTION ? 'calendar' : 'calendar-stg';
export const GEMS_COLLECTION = IS_PRODUCTION ? 'gems' : 'gems-stg';
export const USER_INFO_KEY = 'userInfo';

export const STRIPE_PUBLISH_KEY = IS_PRODUCTION ? 'pk_live_ndRdZMAryciJI126GNjn2wuE' : 'pk_test_P3e8GwkyphGqmyIk5dZgPp6J'

export const METAMOR_COLL = IS_PRODUCTION ? "metamors" : "metamors-stg"
export const LIVE_COLL = IS_PRODUCTION ? "questumLive" : "questumLive-stg"
export const COINS_PACK_COLL = IS_PRODUCTION ? "coins" : "coins-stg"

import {fetchRefreshToken} from '../components/actions/user';
import {auth} from '../firebaseConfig';

export const signInFirebase = async () => {
    try {
        const data = await fetchRefreshToken();
        await auth.signInWithCustomToken(data.firebaseToken);
    } catch (e) {
        console.log(e);
    }
};


import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Delete from '@material-ui/icons/Delete';
import axios from 'axios';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Typography from '@material-ui/core/Typography/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import DelPopup from './DelPopup';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {setGlobal, useGlobal} from 'reactn';
import {API_PREFIX} from '../../config/apiUrl';
import _ from 'lodash';

export const getSubscriptions = async () => {
    try {
        const {data} = await axios.get(
            API_PREFIX + '/subscriptions?service_name=questum');
        // console.log(data)
        let subs = data.map(sub => {
            return {...sub, schedule: sub.schedules[0].uuid};
        });
        setGlobal({
            subscriptions: subs,
        });
        return subs;
    } catch (e) {
        return Promise.reject(e);
    }
};
export const getRankPrices = async () => {
    try {
        const {data} = await axios.get('/rankPrices?service_name=questum');
        // console.log(data)
        setGlobal({
            rankPrices: data,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
};
export default function CartProductList() {
    // const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false);
    const [delAnchorEl, setDelAnchorEl] = useState(null);
    const [delSub, setDelSub] = useState(null);
    const [subscriptions] = useGlobal('subscriptions');
    const [rankPrices] = useGlobal('rankPrices');
    let totalOfTickets = 0;

    useEffect(() => {
        // getProducts()
        getRankPrices();
        getSubs();
    }, []);

    const getSubs = async () => {
        setLoading(true);
        try {
            await getSubscriptions();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const onDelete = sub => e => {
        setDelAnchorEl(e.currentTarget);
        setDelSub(sub);
    };
    const delPopupOnClose = (refresh = false) => {
        setDelSub(null);
        setDelAnchorEl(null);
        if (refresh) {
            getSubs();
        }
    };

    if (loading) return <CircularProgress/>;
    if (!subscriptions.length) return <Typography>セッションはまだありません。</Typography>;
    if (!rankPrices.length) return <Typography>セッションはまだありません。</Typography>;

    totalOfTickets = 0;
    subscriptions.forEach((sub) => {
        let idx = _.findIndex(rankPrices, {rank: sub.rank});
        if (idx !== -1) {
            totalOfTickets = totalOfTickets + rankPrices[idx].price_before_tax *
                sub.coma / 100;
        }
    });
    setGlobal({
        totalOfTickets,
    });

    return (
        <div>
            <List dense>
                {
                    subscriptions.map((sub, idx) => {
                        return (
                            <div key={idx}>
                                <SessionItem buy={sub}
                                             id={`item_${idx}`}
                                    // product={product}
                                             sub={sub}
                                             onDelete={onDelete(sub)}
                                             rankPrices={rankPrices}
                                />
                                {idx === subscriptions.length - 1 && (
                                    <Grid container direction={'column'} justify={'flex-end'}>
                                        <Divider/>
                                        <Grid container justify={'flex-end'}>
                                            <Typography
                                                variant={'subtitle1'} color={'secondary'}
                                                style={{paddingTop: 20, marginRight: 48}}
                                            >{totalOfTickets} コイン</Typography>
                                        </Grid>
                                    </Grid>
                                )
                                }
                            </div>
                        );
                    })
                }
            </List>
            <DelPopup anchorEl={delAnchorEl}
                      sub={delSub}
                      onClose={delPopupOnClose}
            />
        </div>
    );
}

const SessionItem = ({sub, onDelete, rankPrices}) => {
    let idx = _.findIndex(rankPrices, {rank: sub.rank});
    if (idx === -1) return <Typography>セッションはまだありません。</Typography>;
    return (
        <ListItem>
            <ListItemText
                primary={`${sub.contents}・セッション　メタマーランク:${sub.rank}　 コマ:${sub.coma}`}
                secondary={moment(sub.start).format('YYYY-MM-DD(dd) HH:mm〜')}/>
            <ListItemSecondaryAction>
                <Typography variant={'subtitle1'} color={'secondary'}
                            style={{paddingTop: 20}}
                >
                    {rankPrices[idx].price_before_tax * sub.coma / 100}コイン
                    <IconButton onClick={onDelete}>
                        <Delete/>
                    </IconButton>
                </Typography>
            </ListItemSecondaryAction>
        </ListItem>
    );
};


// import React from 'react';
import axios from 'axios';
import {API_PREFIX} from '../../config/apiUrl';
import {setGlobal} from 'reactn';

export async function OldBuyCourse() {
    const buyStringCourse = localStorage.getItem('oldBuyCourse');
    if (buyStringCourse) {
        const listCourse = JSON.parse(buyStringCourse);
        await AddSubscriptions(listCourse);
        localStorage.removeItem('oldBuyCourse');
    }
}

// export async function OldBuyCheck() {
//   const buystring = localStorage.getItem('oldbuyOjb')
//   if (buystring){
//     const buyObj = JSON.parse(buystring)
//     await AddSubscriptions(buyObj)
//     localStorage.removeItem('oldbuyOjb')
//   }
// }

const AddSubscriptions = async (schedule) => {
    try {
        const titless = schedule.title;
        const minutess = schedule.coma * 60;
        const ranks = schedule.metamor.profile.rank;
        const starts = schedule.start;
        await axios.post(API_PREFIX + '/subscriptions/add', {
            'content': titless,
            'minutes': minutess,
            'rank': ranks,
            'start': starts,
            'service_name': 'questum',
            schedule: schedule.uuid,
        });
        setGlobal({showCart: true});
    } catch (e) {

    }
};
